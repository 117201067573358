<template>
  <v-card elevation="0" outlined>
    <v-card-title>
      <span class="font-weight-bold">
        Business Information
      </span>
    </v-card-title>
    <v-card-text>
     
      <v-row>
        <v-col xl="3" md="4" sx="12" sm="12" 
          :class="{'d-flex justify-center': !data.loading }">
          <v-img 
            v-if="data.loading == false"
            :src="LogoCT"
            contain
          />
          <v-skeleton-loader
            v-if="data.loading"
            type="image"
          ></v-skeleton-loader>
          
        </v-col>
        <v-col xl="9" md="8" sx="12" sm="12">
          <div class="d-flex align-center" :class="{'justify-center': data.loading }"> 
            
            <table class="business__information__table" v-if="data.loading == false">
              <tr>
                <th class="font-weight-bold text-subtitle-1">Name:</th>
                <td class="content">{{ data.businessInfo.tenChinhThuc }}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-subtitle-1">Primary exchange address:</th>
                <td class="content">{{ data.businessInfo.diaChiGiaoDichChinh }}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-subtitle-1">Second exchange address:</th>
                <td class="content">{{ data.businessInfo.diaChiGiaoDichPhu }}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-subtitle-1">Tax Code:</th>
                <td class="content">{{ data.businessInfo.maSoThue }}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-subtitle-1">Phone number:</th>
                <td class="content">{{ data.businessInfo.soDienThoai }}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-subtitle-1">Activity status:</th>
                <td class="content">{{ data.businessInfo.trangThaiHoatDong }}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-subtitle-1">Owner:</th>
                <td class="content">{{ data.businessInfo.chuDoanhNghiep }}</td>
              </tr>
              <tr>
                <th class="font-weight-bold text-subtitle-1">Update from:</th>
                <td class="content"> {{ lastUpdateTime }}</td>
              </tr>
            </table>
            <template v-else>
              <v-skeleton-loader
                class="loading"
                type="list-item, list-item, list-item, list-item, list-item"
              ></v-skeleton-loader>
            </template>
          </div>
          <v-divider></v-divider>
          <div class="d-block mt-4 pa-1">
            <v-text-field
              class="search__business__text"
              v-model="searchByTaxCode"
              clearable
              label="Search the business via tax code"
              hint="Enter to search or click to icon"
              persistent-hint
              type="text"
              append-outer-icon="mdi-toy-brick-search-outline"
              @click:append-outer="searchBusinessInfo"
              @keydown.enter="searchBusinessInfo"
              max-width="200px"
            ></v-text-field>    
          </div>

          <div class="d-block mt-2" v-if="data.error">
            <v-alert type="error">
              {{ data.error_messages}}
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import InvoiceService from '@/services/invoice'
import moment from "moment";
import LogoCT from '@/assets/CT_logo.png'
export default {
  name: "BusinessInformationComponent",
  computed: {
    lastUpdateTime() {
      return this.data.businessInfo.LastUpdate == "" ?  "" : moment(this.data.businessInfo.LastUpdate).format("YYYY-MM-DD H:mm:ss")
    }
  },
  data: () => {
    return {
      LogoCT,
      searchByTaxCode: '0302593596', // default
      data: {
        businessInfo: {
          maSoThue: "",
          tenChinhThuc: "",
          diaChiGiaoDichChinh: "",
          diaChiGiaoDichPhu: "",
          trangThaiHoatDong: "",
          soDienThoai: "",
          chuDoanhNghiep: "",
          lastUpdate: ""
        },
        error: false,
        loading: false,
        error_messages :''
      }
    }
  },
  mounted() {
    this.searchBusinessInfo()
  },
  methods: {
    async searchBusinessInfo() {
      this.data.loading = true;
      var result = await InvoiceService.getBusinessInfo(this.searchByTaxCode);
      if (result.error) {
        this.data = {
          ...this.data,
          loading: false,
          error: true,
          error_messages: 'Can not load data! Something went wrong!'
        }
        return;
      }
      
      this.data = {
        businessInfo: result.isError ? {
          maSoThue: "",
          tenChinhThuc: "",
          diaChiGiaoDichChinh: "",
          diaChiGiaoDichPhu: "",
          trangThaiHoatDong: "",
          soDienThoai: "",
          chuDoanhNghiep: "",
          lastUpdate: ""
        } : result.object,
        error: result.isError ? true : false,
        error_messages: result.isError ? result.data : '',
        loading: false,
      }    
    },
    
  }
}
</script>

<style scoped lang="scss">
  .business__information__table {
    border-collapse: collapse;

    th {
      text-align: left;
      font-size: 14px;
      padding: 5px;
    }
    .content {
      font-size: 14px;
      font-weight: bold;
      padding-left: 12px;
      color: #007bff;
    }
  }
  .loading {
    height: 200px;
    width: 100%;
    padding: 5px 0 5px 0;
  }
  .search__business__text {
    width: 400px;
  }
</style>